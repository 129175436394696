<template>
  <div>
    <!-- Start Header Area -->
    <!-- <HeaderOnePageTwo class="d-xs-none d-sm-none d-md-flex hidden-mobile" /> -->
    <!-- <HeaderOnePageTwoMobile class="d-xs-flex d-sm-flex d-md-none" /> -->
    <!-- End Header Area -->

    <!-- Start Slider Area -->
    <div class="slide slide-style-2 slider-video-bg d-flex align-center justify-center overlay-fix"
      data-black-overlay="1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h2 class="heading-title1 text-h2 text-left font-weight-bold"
              style="font-size: 80px !important; line-height: 90px !important; letter-spacing: 2px !important;">
              Geopipe
            </h2>
          </v-col>
          <v-col cols="12" class="inner text-left d-flex flex-column align-start pt-0">
            <h5 class="heading-title1 heading-subtitle text-h5 font-weight-light" style="line-height: 40px !important;">
              Streamline Your Geospatial Data Pipeline<br>Process, Analyze, and Visualize Geospatial Data with Ease</h5>
          </v-col>
        </v-row>
      </v-container>

      <div class="video-background">
        <span>
          <video muted="" autoplay="" playsinline="" loop="" poster="">
            <source src="../assets/images/bg/marine-slider-vid.mp4" />
          </video>
        </span>
      </div>
    </div>
    <!-- End Slider Area -->

    <!-- Start Content -->
    <div class="rn-contact-area bg_color--1">
      <div class="contact-form--1">
        <v-container>
          <div class="section rn-blog-area" id="blog" style="padding-top:120px; padding-bottom:0px !important">
            <div class="section-title section-title--2 text-center mb--20">
              <h4 class="heading-title">Geospasial Pipeline</h4>
              <h3 class="heading-title mb-5">
                One-stop solution for geospatial data processing, analysis, and visualization
              </h3>
              <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
              <p style="text-align: center;" class="my-8">Our platform is designed to streamline your geospatial data
                pipeline, making it easier and more efficient to work with complex geospatial data. Whether you're working
                in mapping, urban planning, agriculture, or natural resource management, our geopipe can help you extract
                insights and value from your data.</p>
            </div>
            <!-- <ServiceMarine /> -->
          </div>
        </v-container>
      </div>
    </div>
    <!-- End Content -->

    <!-- Start feature Area 2  -->
    <div class="rn-team-area rn-section-gapsiber bg_color--1">
      <v-container style="margin-top: 50px; margin-bottom: 50px">
        <v-row class="service-one-wrapper">
          <v-col cols="6">
            <div style="margin: auto; display: flex; width: 600px">
              <v-img contain height="auto" src="../assets/images/about/finding-us-01.jpg" />
            </div>
          </v-col>
          <v-col cols="6" class="d-flex justify-start">
            <div>
              <div class="section-title section-title--2 mb-10">
                <!-- <h4 class="heading-title">Log Monitoring</h4> -->
                <h3 class="heading-title mb-5">
                  Where Mapping Technologies meet
                  with Business Process
                </h3>
              </div>
              <div>
                <p style="font-size: 20px; color: #717173">
                  Harnessing the power of location-based data and spatial analysis, organizations optimize
                  decision-making, streamline
                  operations, and enhance customer experiences by leveraging GIS, geolocation, or real-time
                  mapping solutions to unlock new opportunities to improve efficiency, target markets, and gain a
                  competitive edge in today's dynamic landscape where location intelligence drives success.
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End feature Area 2  -->

    <!-- Start Feature -->
    <div class="rn-team-area rn-section-gapsiber bg_color--5">
      <v-container style="margin-top: 50px; margin-bottom: 50px">
        <v-row class="service-one-wrapper">
          <v-col cols="6" class="d-flex justify-end">

            <div>
              <div class="section-title section-title--2 mb-10">
                <!-- <h4 class="heading-title">Log Monitoring</h4> -->
                <h3 class="heading-title mb-5">
                  Visualizing Analytical Geospatial data in Realtime
                </h3>
              </div>
              <div>
                <p style="font-size: 20px; color: #717173">
                  Realtime visualization of analytical geospatial data by dynamically representing data on
                  interactive maps provides instant insights, empowering decision-makers to grasp complex patterns and
                  trends at a glance and to explore spatial relationships and make informed decisions with speed and
                  accuracy. This powerful capability enhances situational awareness, anomaly detections, and
                  proactive responses in logistics, emergency management, urban
                  planning, and much more.
                </p>
              </div>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="mt-10" style="margin: auto; display: flex; width: 650px">
              <v-img contain height="auto" src="../assets/images/about/finding-us-01.jpg" />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <!-- Start feature Area 2  -->
    <div class="rn-team-area rn-section-gapsiber bg_color--1">
      <v-container style="margin-top: 50px; margin-bottom: 50px">
        <v-row class="service-one-wrapper">
          <v-col cols="6">
            <div style="margin: auto; display: flex; width: 600px">
              <v-img contain height="auto" src="../assets/images/about/finding-us-01.jpg" />
            </div>
          </v-col>
          <v-col cols="6" class="d-flex justify-start">
            <div>
              <div class="section-title section-title--2 mb-10">
                <!-- <h4 class="heading-title">Log Monitoring</h4> -->
                <h3 class="heading-title mb-5">
                  Flexible application Solution
                </h3>
              </div>
              <div>
                <p style="font-size: 20px; color: #717173">
                  Flexible application solutions offer real-time visualization of analytical geospatial data. By
                  leveraging cutting-edge technologies, these solutions provide dynamic and interactive maps that allow
                  users to explore and analyze data with ease. With the ability to adapt to changing needs and integrate
                  various data sources, they empower businesses to make informed decisions and gain valuable insights from
                  their geospatial data in real-time.
                </p>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End feature Area 2  -->

    <!-- Start feature Area 3  -->
    <!-- <div class="rn-team-area rn-section-gapsiber bg_color--5">
      <v-container style="margin-top: 50px; margin-bottom: 80px">
        <v-row class="service-one-wrapper">
          <v-col cols="6" class="d-flex justify-end">
            <div>
              <div class="section-title section-title--2 mb-10">
                <span style="font-size: 14px; font-weight: 700;">NEXTGEN FIREWALL</span>
                <h3 class="heading-title mb-5">
                  Monitoring your firewall
                </h3>
                <span style="font-size: 14px; color: #717173">Analyze your environment at will</span>
              </div>
              <div>
                <p style="font-size: 20px; color: #717173">Interactively monitor years of historical data — without
                  breaking your budget. Quickly grasp unfolding attacks by correlating all relevant data. Throughout the
                  UI, access built-in trend charts for key data fields. And do it all with the only dashboard fast enough
                  for the quickest analysts.</p>
              </div>
            </div>
          </v-col>
          <v-col cols="6">
            <div style="margin: auto; display: flex; width: 650px">
              <v-img contain height="auto" src="../assets/images/featured/firewall.png" />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div> -->
    <!-- End Feature -->

    <!-- Start feature Area  -->

    <div class="rn-portfolio-area rn-section-gap bg_color--5" id="recognitionAwards">
      <div class="portfolio-sacousel-inner pb--30">
        <v-container>
          <v-row>
            <v-col lg="12">
              <div class="section-title text-left mb--20 mb_sm--0 mb_md--0">
                <h4 class="heading-title">Key Features</h4>
                <p>
                  Our platform comes equipped with a range of powerful features to help you work with geospatial data more
                  effectively:
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <div>
          <!-- Start Portfolio Activation  -->
          <VueSlickCarousel v-bind="settings"
            class="rn-slick-activation portfolio-slick-activation item-fluid slick-dotted rn-slick-dot mt--40 mt_sm--40">
            <!-- Start Single Portfolio  -->
            <div class="portfolio2" v-for="(item, i) in portfolioContent" :key="i">
              <div class="content">
                <div class="inner">
                  <p>{{ item.meta }}</p>
                  <h5>
                    <router-link to="portfolio-details">{{ item.title }}</router-link>
                  </h5>
                </div>
              </div>
            </div>
          </VueSlickCarousel>
          <!-- End Single Portfolio  -->
        </div>
      </div>
    </div>


    <div class="section rn-blog-area rn-section-gap bg_color--5">

      <div class="section-title section-title--2 text-center mb--20 mb_sm--0 mb_md--0">
        <h4 class="heading-title">How It Works</h4>
        <h3 class="heading-title mb-5">
          How we approach the Geospasial Pipeline Solution
        </h3>
        <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
        <!-- <p style="text-align: center;" class="my-8">Sibernetik strives to design and deliver the right real-time data
          streaming solution to your company.<br><b style="font-weight: 700;">Preffered Local Partner</b> of best product
          principles in real-time data streaming ecosystem,<br /> trusted by half of the FORTUNE 100</p> -->
      </div>
      <div style="padding: 0 230px 0 230px">
        <!-- <PrincipalProducts /> -->

        <v-row style="justify-content: center !important; margin-top: 20px !important; margin-bottom: 20px !important;">

          <!-- Start Portfolio Activation  -->
          <v-col cols="12">
            <v-row style="justify-content: center !important;margin-top: 50px; margin-bottom: 50px;">
              <v-col xl="10" lg="10" md="10" sm="10" cols="12" class="mx-xl-12 mx-lg-12 mx-md-12 mx-sm-auto mx-xs-auto">
                <v-slide-y-reverse-transition>
                  <v-row v-if="showContent" justify="start">
                    <!-- <div class="content"> -->
                    <v-col class="d-flex align-center justify-end">
                      <img :src="serviceWhite[activeService].logo" style="border-radius: 0%; width: 1000px;" />
                    </v-col>
                    <v-col>
                      <h3 class="title text-left" style="color:darkgray; padding-bottom: 10px;">{{
                        serviceWhite[activeService].title }}</h3>
                      <p class="text-left"
                        style="font-size: 14px; box-shadow: 0px 0px 10px 0px rgba(128, 128, 128, 0.5); background-color: white; padding: 10px;">
                        {{ serviceWhite[activeService].description }}</p>
                    </v-col>
                    <!-- </div> -->
                  </v-row>
                </v-slide-y-reverse-transition>
              </v-col>
            </v-row>

            <!-- <v-row style="justify-content: center !important; ">
              <v-col xl="6" lg="7" md="7" sm="10" cols="10" class="mx-xl-10 mx-lg-10 mx-md-10 mx-sm-auto mx-xs-auto">
                <v-slide-y-reverse-transition>
                  <div v-if="showContent">
                    <div>
                      <div class="content">
                        <div>
                          <h3 class="title text-left" style="color:darkgray; padding-bottom: 10px;">{{
                            serviceWhite[activeService].title }}</h3>
                        </div>
                        <p class="text-left"
                          style="font-size: 14px; box-shadow: 0px 0px 10px 0px rgba(128, 128, 128, 0.5); background-color: white; padding: 10px;">
                          {{ serviceWhite[activeService].description }}</p>
                      </div>
                    </div>
                  </div>
                </v-slide-y-reverse-transition>
              </v-col>
            </v-row> -->
          </v-col>
          <!-- End Single Portfolio  -->
        </v-row>
        <v-row style="margin-bottom: 20px;">
          <v-bottom-navigation shift style="background-color: rgba(0,0,0,0); box-shadow: none;" v-model="activeService">
            <v-btn v-bind:key="index" v-for="(item, index) in serviceWhite">
              <!-- <img :src="item.logo" style="border-radius: 0%;" /> -->
              <v-icon>mdi-circle-medium</v-icon>
            </v-btn>
          </v-bottom-navigation>
        </v-row>

        <!-- Start Service Area  -->
        <div class="section rn-blog-area rn-section-gap bg_color--5">

          <div class="section-title section-title--2 text-center mb--20 mb_sm--0 mb_md--0">
            <h4 class="heading-title">Benefits of Using Geopipe</h4>
            <h3 class="heading-title mb-5">
              Key benefits you can expect when using our platform:
            </h3>
            <hr color="#dd8732" width="200px;" style="margin: auto; border-top: 4px  solid #dd8732">
            <!-- <p style="text-align: center;" class="my-8">Sibernetik strives to design and deliver the right real-time data
          streaming solution to your company.<br><b style="font-weight: 700;">Preffered Local Partner</b> of best product
          principles in real-time data streaming ecosystem,<br /> trusted by half of the FORTUNE 100</p> -->
          </div>
          <v-row class="service-one-wrapper">
            <!-- Start Single Service  -->
            <v-col lg="6" md="6" sm="6" cols="12" v-for="(service, i) in serviceContent" :key="i">
              <div class="single-service service__style--siber" style="height: 300px !important;">
                <router-link to="/service-details">
                  <div class="service">
                    <div class="icon" v-html="iconSvg(service.icon)"></div>
                    <div class="content">
                      <h3 class="heading-title">{{ service.title }}</h3>
                      <p>
                        {{ service.desc }}
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
            </v-col>
            <!-- End Single Service  -->
          </v-row>
        </div>
      </div>
    </div>
    <!-- End feature Area  -->
    <!-- End Content -->
    <!-- <Footer /> -->
  </div>
</template>

<script>
import HeaderOnePageTwo from "../components/header/HeaderOnePageTwoSiber.vue";
import HeaderOnePageTwoMobile from "../components/header/HeaderOnePageTwoSiberMobile.vue";
import Contact from "../components/contact/Contact";
import FeatureArea from "../components/brand/BrandFeatureMarine.vue";
import ServiceMarine from "../components/service/ServiceMarine.vue";
import Footer from "../components/footer/Footer";
import VueSlickCarousel from "vue-slick-carousel";
import feather from "feather-icons";
export default {
  components: {
    HeaderOnePageTwo,
    HeaderOnePageTwoMobile,
    Contact,
    FeatureArea,
    ServiceMarine,
    Footer,
    // PortfolioAwards,
    VueSlickCarousel,
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg()
    }
  },
  data() {
    return {
      serviceContent: [
        {
          icon: "briefcase",
          title: "Streamline Your Workflow",
          desc: `Our platform is designed to make working with geospatial data easier and more efficient, saving you time and effort.`,
        },
        {
          icon: "check",
          title: "Increase Accuracy and Consistency",
          desc: `Our built-in data cleaning and preprocessing tools help ensure the accuracy and consistency of your data.`,
        },
        {
          icon: "bar-chart",
          title: "Extract Valuable Insights",
          desc: `Our range of spatial analysis and modeling tools can help you extract valuable insights and patterns from your geospatial data.`,
        },
        {
          icon: "pie-chart",
          title: "Create Stunning Visualizations",
          desc: `Our powerful visualization tools and templates make it easy to create stunning visualizations of your geospatial data, helping you communicate your insights effectively.`,
        },
      ],
      serviceWhite: [
        {
          img: require("../assets/images/about/finding-us-01.jpg"),
          logo: require("../assets/images/about/finding-us-01.jpg"),
          title: 'Ingest Data',
          description: 'Our platform simplifies the process of ingesting geospatial data. With our adaptable engine, you can easily import your data in various formats, such as shapefiles or geodatabases, ensuring compatibility with your existing datasets.',
          linkDetail: '/'
        },
        {
          img: require("../assets/images/about/finding-us-01.jpg"),
          logo: require("../assets/images/about/finding-us-01.jpg"),
          title: 'Clean and Preprocess',
          description: 'Save time and effort with our built-in tools and scripts that streamline data cleaning and preprocessing. Detect and eliminate errors, validate data integrity, and standardize formats, ensuring the accuracy and reliability of your geospatial information.',
          linkDetail: '/'
        },
        {
          img: require("../assets/images/about/finding-us-01.jpg"),
          logo: require("../assets/images/about/finding-us-01.jpg"),
          title: 'Analyze and Model',
          description: 'Unlock the full potential of your geospatial data using our comprehensive suite of spatial analysis techniques. Explore relationships, perform spatial queries, conduct statistical analyses, and generate models to reveal patterns, trends, and valuable insights.',
          linkDetail: '/'
        },
        {
          img: require("../assets/images/about/finding-us-01.jpg"),
          logo: require("../assets/images/about/finding-us-01.jpg"),
          title: 'Visualize and Share',
          description: 'Transform your geospatial data into captivating visualizations. Utilize our powerful visualization tools and templates to create stunning maps, charts, and interactive displays. Easily share your visualizations and insights with stakeholders, enabling effective communication and collaboration.',
          linkDetail: '/'
        },
      ],
      showContent: true,
      activeService: 0,
      settings: {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3, // Adjusted value based on the number of items
        slidesToScroll: 1,
        initialSlide: 0,

        // responsive: [
        //   {
        //     breakpoint: 1600,
        //     settings: {
        //       slidesToShow: 4,
        //       slidesToScroll: 1,
        //       infinite: true,
        //       dots: true,
        //     },
        //   },
        //   {
        //     breakpoint: 1263,
        //     settings: {
        //       slidesToShow: 3,
        //       slidesToScroll: 1,
        //       infinite: true,
        //       dots: true,
        //     },
        //   },
        //   {
        //     breakpoint: 770,
        //     settings: {
        //       slidesToShow: 2,
        //       slidesToScroll: 1,
        //       initialSlide: 1,
        //     },
        //   },
        //   {
        //     breakpoint: 599,
        //     settings: {
        //       slidesToShow: 2,
        //       slidesToScroll: 1,
        //       initialSlide: 1,
        //     },
        //   },
        //   {
        //     breakpoint: 480,
        //     settings: {
        //       slidesToShow: 1,
        //       slidesToScroll: 1,
        //       arrows: false,
        //     },
        //   },
        // ],
      },
      portfolioContent: [
        {
          meta: "Data Ingestion",
          title: "Easily upload and import geospatial data from a variety of sources, including cloud storage, databases, and APIs.",
        },
        {
          meta: "Data Cleaning and Preprocessing",
          title: "Clean, validate, and preprocess your data to ensure accuracy and consistency.",
        },
        {
          meta: "Spatial Analysis and Modeling",
          title: "Analyze and model your geospatial data using a range of spatial analysis techniques and tools.",
        },
        {
          meta: "Data Visualization",
          title: "Create stunning visualizations of your geospatial data using our powerful visualization tools and templates.",
        },
      ],
      features: [
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/monitor.svg"),
          titleFeatures: "Vessel Traffic Monitoring",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/filter.svg"),
          titleFeatures: "Vessel Search & Filtering",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/info.svg"),
          titleFeatures: "Vessel Voyage & Port Information Panel",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/play.svg"),
          titleFeatures: "Playback Voyage",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/database.svg"),
          titleFeatures: "Real-time Data Source Processing",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/vessel.svg"),
          titleFeatures: "Vessel Even",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/cloud.svg"),
          titleFeatures: "Weather Forecast",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/bell.svg"),
          titleFeatures: "Custom Alert & Notification",
        },
        {
          // iconFeatures: require("../assets/images/icons/logo-icon.png"),
          iconFeatures: require("../assets/images/icons/pie-chart.svg"),
          titleFeatures: "Analytics & Reporting",
        },
      ]
    };
  },
  watch: {
    activeService(e, v) {
      // console.log(e)
      this.showContent = false
      this.$nextTick(() => {
        this.showContent = true
      })
    },
  }
};
</script>
<style scoped>
.heading-title1 {
  color: #dd8732 !important;
  line-height: 60px;
  font-family: 'Urbanist', sans-serif !important;
}

.heading-title2 {
  color: #f7f6f1 !important;
  font-family: 'Urbanist', sans-serif !important;
}

.heading-subtitle {
  color: #f7f6f1 !important;
  font-family: 'Urbanist', sans-serif !important;
}

.heading-subtitle2 {
  color: #dd8732 !important;
  font-family: 'Urbanist', sans-serif !important;
}

.heading-caption {
  color: #f7f6f1 !important;
  font-family: 'Urbanist', sans-serif !important;
  font-size: 16px !important;
  line-height: 25px;
  font-weight: 500;
}

.heading-caption2 {
  color: #f7f6f1 !important;
  font-family: 'Urbanist', sans-serif !important;
  font-size: 16px !important;
  line-height: 25px;
  font-weight: 500;
}

.item-subtitle {
  color: #E18122 !important;
  font-family: 'Urbanist', sans-serif !important;

}

.bg-feature {
  background-image: linear-gradient(45deg, #232526, #414345);
}
</style>
